<template>
  <div class="st-password" :class="props.rulesPosition">
    <StInput
      ref="input"
      v-model="inputValue"
      :data-t="dataT"
      :error-message="errorMessage"
      :label="label"
      :placeholder="placeholder"
      :type="type"
      :hint="hint"
      :error="error"
      :disabled="disabled"
      :size="props.size"
      @blur="handleBlur"
      @focus="handleFocus"
    >
      <template #tip>
        <slot name="tip" />
      </template>
      <template #icon-posfix>
        <StIcon
          class="password-eye"
          :name="type === 'password' ? 'eye-on-solid' : 'eye-off-solid'"
          :size="20"
          @click="handleShowPassword"
        />
      </template>
    </StInput>
    <div v-if="isShownRules" class="password-dropdown">
      <ul class="password-rules" data-t="password-rules">
        <li
          v-for="(rule, index) in formattedRules"
          :key="index"
          class="password-rule"
          :class="rule.class"
          data-t="password-rule"
        >
          <StIcon name="check" :size="12" />
          <span>{{ rule.errorMessage }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
interface PasswordValidationRule {
  errorMessage: string
  rule: (password: string) => boolean
}

const props = withDefaults(
  defineProps<{
    modelValue?: string
    label?: string
    placeholder?: string
    hint?: string
    error?: boolean
    errorMessage?: string
    disabled?: boolean
    autofocus?: boolean
    autocomplete?: string
    maxlength?: number
    dataT?: string
    rules?: PasswordValidationRule[]
    rulesPosition?: 'top' | 'bottom'
    size?: 'l' | 'm' | 's'
  }>(),
  {
    modelValue: '',
    label: '',
    placeholder: '',
    hint: '',
    errorMessage: '',
    autocomplete: '',
    maxlength: 999,
    iconPrefix: '',
    disabled: false,
    dataT: 'st-input-password',
    rules: () => [],
    rulesPosition: 'bottom',
    size: 'l',
  },
)
const type = ref<'password' | 'input'>('password')

const input = ref<HTMLInputElement>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'blur'): void
  (e: 'focus'): void
}>()

onMounted(() => {
  if (props.autofocus) input.value?.focus()
})

const inputValue = computed({
  get: () => props.modelValue,
  set: (value: string) => emit('update:modelValue', value),
})

function handleShowPassword() {
  type.value = type.value === 'input' ? 'password' : 'input'
}

const isFocused = ref(false)
const formattedRules = computed(
  () =>
    props.rules?.map(({ rule: validate, errorMessage }) => {
      const isValid = validate(props.modelValue)

      return { errorMessage, isValid, class: { success: isValid } }
    }),
)
const isAllRulesValid = computed(
  () => formattedRules.value?.every((rule) => rule.isValid),
)
const isShownRules = computed(
  () => props.rules.length && isFocused.value && !isAllRulesValid.value,
)

const rulesOffset = computed(() => {
  if (props.rulesPosition === 'bottom') {
    return props.label ? '74px' : '48px'
  }
  return props.hint ? '74px' : '48px'
})

function handleFocus() {
  emit('focus')
  isFocused.value = true
}

function handleBlur() {
  emit('blur')
  isFocused.value = false
}
</script>

<style scoped>
.st-input-icon {
  width: 16px;
  height: 16px;
}

.password-dropdown {
  position: absolute;
  z-index: 2;
  top: v-bind(rulesOffset);
  right: 0;
  left: 0;

  margin-top: var(--spacing-100);
  margin-bottom: var(--spacing-100);
  padding: var(--spacing-150);

  background: var(--background-primary);
  border-radius: var(--border-radius-100);
  box-shadow:
    0 4px 8px -2px rgb(0 0 0 / 24%),
    0 16px 48px -4px rgb(0 0 0 / 24%);
}

.st-password {
  position: relative;
  display: flex;
  flex-direction: column;

  &.top {
    flex-direction: column-reverse;

    .password-dropdown {
      top: unset;
      bottom: v-bind(rulesOffset);
    }
  }
}

.password-rules {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);

  margin: 0;
  padding: 0;

  list-style: none;
}

.password-rule {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;

  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);

  &.success {
    color: var(--system-success);
  }
}

.password-eye {
  cursor: pointer;
  color: var(--text-tertiary);

  &:hover {
    color: var(--text-primary);
  }
}
</style>
