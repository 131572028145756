<template>
  <header
    class="header"
    :class="{ base: !props.withBackButton, border: !noBottomLine }"
  >
    <button v-if="props.withBackButton" class="button" @click="emit('back')">
      <StIcon name="chevron-left" :size="20" />
    </button>
    <slot></slot>
    <button class="button close" @click="close">
      <StIcon name="cross-large" :size="20" />
    </button>
  </header>
</template>

<script setup lang="ts">
const props = defineProps<{
  withBackButton?: boolean
  noBottomLine?: boolean
}>()

const emit = defineEmits<{
  (e: 'back'): void
}>()

const router = useRouter()
function close() {
  router.replace({ query: {} })
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  padding: var(--spacing-050);

  &.border {
    border-bottom: var(--border-width-light) solid var(--border-primary);
  }

  &.base {
    padding-left: var(--spacing-100);
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-125);

  color: var(--text-primary);

  background-color: transparent;
  border: none;
  outline: none;

  &.close {
    margin-left: auto;
  }
}
</style>
