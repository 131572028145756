import { computed, type Ref } from 'vue'
import { useField } from '../useField'

import type { FieldSchema, Field } from '../types'

function mapObject<T, MappedValue>(
  object: T,
  mapCallback: (value: T[keyof T], key: keyof T, obj: T) => MappedValue,
): Record<keyof T, MappedValue> {
  const newObject: any = {}

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in object) {
    const value = object[key]
    newObject[key] = mapCallback(value, key, object)
  }

  return newObject
}

type FieldsSchema<T> = {
  [FieldName in keyof T]: FieldSchema<T[FieldName]>
}

type Fields<T> = {
  [FieldName in keyof T]: Field<T[FieldName]>
}

export interface UseFormParams<T extends Record<string, unknown>> {
  fieldsSchema: FieldsSchema<T>
}

export interface UseFormReturn<T extends Record<string, unknown>> {
  fields: Fields<T>
  isReadyValidation: Readonly<Ref<boolean>>
  isValid: Readonly<Ref<boolean>>
  values: Readonly<Ref<T>>
  reset: () => void
}

export function useForm<T extends Record<any, unknown>>({
  fieldsSchema,
}: UseFormParams<T>): UseFormReturn<T> {
  const fields = mapObject(fieldsSchema, (schema) =>
    useField(schema),
  ) as Fields<T>

  const isReadyValidation = computed(() =>
    Object.values(fields).every((field) => field.isReadyValidation),
  )

  const isValid = computed(() =>
    Object.values(fields).every((field) => field.isValid),
  )

  const values = computed(() => mapObject(fields, ({ value }) => value) as T)

  function reset() {
    Object.keys(fields).forEach((key) => {
      fields[key].reset()
    })
  }

  return {
    isReadyValidation,
    isValid,
    fields,
    values,
    reset,
  }
}
