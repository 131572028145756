export const required = (value: unknown) => !!value

export const validEmail = (value: string) => {
  const regExp = /^[^а-яА-ЯёЁ\s]+@(?:[a-zA-Z0-9][-a-zA-Z0-9]+\.)+[a-z]{2,6}$/
  return regExp.test(value)
}

export function lengthBetween(minLength: number, maxLength: number) {
  return (password: string) =>
    password.length >= minLength && password.length <= maxLength
}

export function atLeastDigit(count: number) {
  return (value: string) => {
    const digitCount = value.match(/\d/g)?.length ?? 0
    return digitCount >= count
  }
}

export function atLeastLetter(count: number) {
  return (value: string) => {
    const lettersCount = value.match(/[a-z]/gi)?.length ?? 0
    return lettersCount >= count
  }
}

export function atLeastSpecialCharacter(count: number) {
  return (value: string) => {
    const specialCharsCount =
      value.match(/[!"#$%&'\\()*+,\-./:;<=>?@[\]^_`{|}~]/g)?.length ?? 0
    return specialCharsCount >= count
  }
}

export function onlyLatinOrDigitsOrSpecialCharacter(value: string) {
  return (
    !!value &&
    !!value.match(/^[a-zA-Z0-9!"#$%&'\\()*+,\-./:;<=>?@[\]^_`{|}~]+$/)
  )
}

export function atLeastCapitalLetter(value: string) {
  return !/^[^A-Z]*$/.test(value)
}

export function atLeastLowerLetter(value: string) {
  return !/^[^a-z]*$/.test(value)
}
